.newsletter-editor {
  display: flex;
  flex-direction: column;
  gap: 60px;
  align-items: center;
  padding-bottom: 50px;

  .input {
    width: 100%;
  }

  #target-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 30px;

    .choice {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}